import axios from 'axios';
import Cookies from 'js-cookie';

export const getTowerImage = async (tower_id, image_number) => {
    let token = Cookies.get('auth_token')
    try {
    const response = await axios.post('/api/locations/towers/image/get', {
        token,
        tower_id,
        image_number
    });
    console.log(image_number);
    console.log(tower_id);
    return response.data
    } catch (error) {
    console.error('Error:', error);
    return 'Error';
    }
};