import React, { useState } from "react";

import Sales from "../pages/Sales"
import Schedule from "../pages/Schedule";
import Map from "../pages/Map";
import List from "../pages/List";
import Emerald from "../pages/Emerald";

const AppContent = ({pageInView, setPageInView, searchText}) => {


    return (
        <>
            {pageInView == "sales" && <Sales/>}
            {pageInView == "schedule" && <Schedule/>}
            {pageInView == "map" && <Map/>}
            {pageInView == "list" && <List />}
            {pageInView == 'emerald' && <Emerald />}
        </>
        )
    }

export default AppContent;