import { Accordion, Alert, Button, Group, Radio, Select, Slider, Space, Stack, Switch, Text, TextInput, Textarea, Loader } from "@mantine/core";
import {IconAlertCircle} from "@tabler/icons-react"
import {DateInput} from "@mantine/dates"
import { useEffect, useState } from "react";

import {getInernetServiceTypes} from "../../hooks/services/useGetInternetServiceTypes"
import { getRouterServiceTypes } from "../../hooks/services/useGetRouterServiceTypes";
import { getInstallAvailability } from "../../hooks/scheduling/useGetInstallAvailability";
import { getReferral } from "../../hooks/referral/useGetReferral";
import { randomId } from "@mantine/hooks";


export default function ServiceAndScheduling({
    speedPlan, 
    setSpeedPlan, 
    router, 
    setRouter,
    installAppointment,
    setInstallAppointment, 
    handleSubmit, 
    formErrors,
    oneTimeDiscount,
    setOneTimeDiscount,
    specialNotes,
    setSpecialNotes,
    submitDisabled,
    setReferralCode,
    referralCode
    }){



    const [selectedDate, setSelectedDate] = useState(new Date());
    const [apptOptions, setApptOptions] = useState()
    const [speedPlans, setSpeedPlans] = useState()
    const [routerOptions, setRouterOptions] = useState()
    const [unfilteredReferralOptions, setUnfilteredReferralOptions] = useState([])
    const [filteredReferralOptions, setFilteredReferralOptions] = useState([])
    const [fiftyOff, setFiftyOff] = useState(false)
    const [twoStory, setTwoStory] = useState(false)
    const [appointmentId, setAppointmentId] = useState()
    

    const [appointmentsLoading, setAppointmentsLoading] = useState(false)
    

    const customerStatusOptions = [
        {value: 1, label: "No Coverage"},
        {value: 2, label: "Lead/Callback"},
        {value: 3, label: "Signed Up, Awaiting Install"}
    ]

    function installerNames(truckNumber){
        if(truckNumber == 3){
            return "Hunter"
        }
        if(truckNumber == 9){
            return "Nicolas"
        }
    }

function showLoader(bool){
    if(bool){
        return <Loader color="orange" size={"xs"}/>
    }else{
        return <></>
    }
}

function handleReferralSearch(value){
    setReferralCode(value)
}

//called when the user changes the fifty off switch
useEffect(()=>{ 
    if(fiftyOff){
        setOneTimeDiscount("$50 Off Install")
    }else{
        setOneTimeDiscount("")
    }

}, [fiftyOff])

//called when the appointment ID get's changed
useEffect(()=>{
    if(apptOptions && appointmentId){
        setInstallAppointment(apptOptions.find(appointment=>appointment.id === appointmentId))
    }else{

    }
    
}, [appointmentId])

// called when the user changes the two-story switch
useEffect(() => {
    if(twoStory){
        setSpecialNotes("2 Story\n" + specialNotes)
    }else{
        setSpecialNotes(specialNotes.replace("2 Story\n", ""))
    }
}, [twoStory])

//no item in the array of useeffect, so only gets called when the component get's rendered.
    useEffect(()=>{
        setSpeedPlans([])
        setRouterOptions([])

        getReferral()
        .then((referrals)=>{
            setUnfilteredReferralOptions(referrals)
            let holderArray = []
            referrals.map((item)=>{
                holderArray.push({label: item.first_name+" "+item.last_name, value: item.ref_code_public})
            })
            
            setFilteredReferralOptions(holderArray)
        })
        getInernetServiceTypes()
        .then((speed_plans)=>{
            let speedPlanHolder = []
            speed_plans.data.forEach((item)=>{
                let id = item[0]
                let name = item[1] +" - $" + item[3].toString()
                speedPlanHolder.push({value: id, label: name})
            })
            return speedPlanHolder  
        })
        .then((holder_array)=>{
            setSpeedPlans(holder_array)
        })

        getRouterServiceTypes()
        .then((routers)=>{
            let router_holder = []
            routers.data.forEach((item)=>{
                let id = item[0]
                let name = item[1] +" - $" + item[3].toString()
                router_holder.push({value: id, label: name})
            })
            return router_holder  
        })
        .then((router_holder)=>{
            setRouterOptions(router_holder)
        })
        
    }, [])


//Function that is called when selected date in the box changes.
useEffect(()=>{
    if(selectedDate){
        setAppointmentsLoading(true)
        setApptOptions()
        setInstallAppointment()
        setAppointmentId()
        getInstallAvailability(selectedDate.toLocaleDateString())
        .then((appointments) =>{
            let holder_array = []
            appointments.data.forEach((item)=>{
                let time = new Date(item.time)
                holder_array.push({time: time, installer: item.installer_group, id: randomId()})
            })
            return holder_array
        })
        .then((appointmentSlots)=>{
            setApptOptions(appointmentSlots)
            setAppointmentsLoading(false)
        })
    }

}, [selectedDate])

    return(
        <Stack>
            {speedPlans &&    <Select error={formErrors.speedPlan} value={speedPlan} onChange={setSpeedPlan} label="Speed Plan" placeholder="Choose A Plan" data={speedPlans} />}
            {routerOptions && <Select error={formErrors.router} value={router} onChange={setRouter} label="Router Option" placeholder="Choose a Router" data={routerOptions} />}
            <Switch label="Two-story Home" value={twoStory} onChange={(e)=>{setTwoStory(e.target.checked)}}/>
            
            
            {formErrors.installAppointment && <Alert icon={<IconAlertCircle />} color="red" title="Please select an appointment time." />}
            <Accordion defaultValue={"scheduling"}>
                
                <Accordion.Item value="scheduling">
                <Accordion.Control>
                    Scheduling{installAppointment && " - "+installAppointment.time+ " -  " + installerNames(installAppointment.installer)}
                </Accordion.Control>
                <Accordion.Panel>
                    <DateInput rightSection={showLoader(appointmentsLoading)} allowDeselect value={selectedDate} onChange={setSelectedDate} label="Soonest Date Customer Is Available"/>
                        <Radio.Group error={formErrors.installAppointment} value={appointmentId} onChange={setAppointmentId}  >
                            <Space h={"md"} />
                            <Group>
                                {apptOptions && apptOptions.map((item)=> {return<Radio key={randomId()} value={item.id} label={item.time.toLocaleString() + " -  " + installerNames(item.installer)} />})}
                            </Group>
                        </Radio.Group>
                        {appointmentId &&
                            <>
                            <Space h={"md"} />
                            <Button color="orange" onClick={()=>{setInstallAppointment(); setAppointmentId(); setApptOptions(apptOptions);}}>Clear Selected Install</Button>
                            </>               
                        }
                
                </Accordion.Panel>
                </Accordion.Item>


                <Accordion.Item value="install_notes">
                    <Accordion.Control>Notes for Installer</Accordion.Control>
                    <Accordion.Panel>
                    <Textarea placeholder="Anything important to tell the installer?" minRows={10} value={specialNotes} onChange={(e) =>{setSpecialNotes(e.target.value)}}/>

                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            
            <Text>Referral Name or Discount Code</Text>
            <Group grow>
                <Select value={referralCode} onChange={setReferralCode} data={filteredReferralOptions} filter={(value, item) => item.label.toLowerCase().includes(value.toLowerCase().trim())} searchable maxDropdownHeight={400} />
                <TextInput value={referralCode} disabled={true}/>
                <Switch label="$50 Off Install"  value={fiftyOff} onChange={(e)=>{setFiftyOff(e.target.checked)}}/>
                </Group>
            <Button disabled={submitDisabled} onClick={handleSubmit} color="green">Save Customer Information</Button>
        </Stack> 
    )
}