import { Grid, Paper, SegmentedControl, Select, Group, Text, TextInput, Button, Table } from "@mantine/core";
import { useState } from "react";

export default function PreInstallList({items, clickCreate}){
    const rows = items.map((customer)=>{
        var id = customer['id'];
        var name = customer['first_name']+" "+ customer["last_name"];
        var addressOne = customer["address_one"];
        var addressTwo = customer["address_two"];
        var city = customer["city"];
        var state = customer['state'];
        var zip = customer['zip'];
        var discount = "$"+ customer['one_time_discount'];
        var pppoeUser = customer['pppoe_username'];
        var pppoePass = customer['pppoe_password'];
        var referralCode = customer['referral_code'];
        var address = addressOne + " "+addressTwo+" "+city+" "+" "+state+" "+zip
    return(
        <tr key={id}>
            <td>{name}</td>
            <td>{address}</td>
            <td>{discount}</td>
            <td>{pppoeUser}</td>
            <td>{pppoePass}</td>
            <td>{referralCode}</td>
            <td><Button onClick={(e) => {clickCreate(id)}}>Create MBR</Button></td>
        </tr>
        )
    })
    return(
        <Table mx={15}>
            <thead>
                <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Install Discount</th>
                <th>PPPoE Username</th>
                <th>PPPoE Password</th>
                <th>Referral Code</th>
                <th>Action</th>
                </tr>
            </thead>
        <tbody>{rows}</tbody>
        </Table>
    )
}