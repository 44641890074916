import { Grid, Paper, SegmentedControl, Select, Group, Text, TextInput, Table, Modal } from "@mantine/core";
import { useEffect, useState } from "react";

import GenericList from "../components/general/GenericList";
import ScheduleList from "../components/scheduling/ScheduleList";
import TowerList from "../components/towers/TowerList";
import TowerDetail from "../components/towers/TowerDetail"
import PreInstallList from "../components/emerald/PreInstallList";
import {getServiceAppts} from "../hooks/scheduling/useGetServiceAppts";
import {getTowers} from "../hooks/locations/useGetTowers";
import { getPreInstalls } from "../hooks/emerald/useGetPreInstall";
import { createMBR } from "../hooks/emerald/useCreateMBR";

export default function List(){
    const [itemsToList, setItemsToList] = useState("installs")
    const [generalSearchTerm, setGeneralSearchTerm] = useState("")
    const [numberOfRows, setNumberOfRows] = useState(25);
    const [orderBy, setOrderBy] = useState("");
    const [reloadListData, setReloadListData] = useState(false);

    const breakPointMd = 12
    const breakPointLg = 4


    const [scheduleItems, setScheduleItems] = useState([])
    const [towerItems, setTowerItems] = useState([])
    const [preInstallItems, setPreInstallItems] = useState([])

    const [towerDetailID, setTowerDetailID] = useState();
    const [towerDetailModalOpened, setTowerDetailModalOpened] = useState();

    useEffect(() =>{
        if(itemsToList == 'scheduling'){
            getServiceAppts(numberOfRows, orderBy, generalSearchTerm)
            .then((res)=>{
                setScheduleItems(res.data)
                
            });
        }
    }, [generalSearchTerm, numberOfRows, itemsToList, reloadListData])

    useEffect(() => {
        if(itemsToList =='towers'){
            getTowers()
            .then((res)=>{
                setTowerItems(res.towers)
            });
        }
    }, [itemsToList, generalSearchTerm, reloadListData])

    useEffect(() =>{
        if(itemsToList=='installs'){
            getPreInstalls()
            .then((res)=>{
                setPreInstallItems(res);
            })
        }
    }, [itemsToList, generalSearchTerm, reloadListData])

    function leftClickView(tower){
        setTowerDetailID(tower[0]);
        setTowerDetailModalOpened(true);
    }

    function handleCloseTowerDetail(){
        setTowerDetailModalOpened(false); 
    }
    function handleCreateMbr(preInstallId){
        console.log(preInstallId)
        if(window.confirm("Are you sure you want to create this MBR?")){
            
            createMBR(preInstallId)
            .then((res)=>{
                if(res.status == "success"){
                    setReloadListData(!reloadListData);
                }
                
            })
        }
    }
    
    return(
        <>
            <Paper withBorder radius={0} >

                <Grid  py={15} >

                    <Grid.Col pl={20} md={breakPointMd}  lg={breakPointLg}  align="center" >
                        <Group position="center">

                        <Text>Results:</Text>
                        <Select value={numberOfRows} onChange={setNumberOfRows} data={[
                            {label: "25", value: 25},
                            {label: "50", value: 50},
                            {label: "100", value: 100},
                            {label: "200", value: 200},
                            {label: "500", value: 500}
                        ]} />
                        </Group>
                        
                    </Grid.Col>

                    <Grid.Col align="center" bg="white" md={breakPointMd}  lg={breakPointLg}>
                        <SegmentedControl
                            value={itemsToList}
                            onChange={setItemsToList} 
                            data={[
                                {label: "Towers", value: "towers"},
                                {label: "Devices", value: "devices", disabled: true},
                                {label: "Customers", value: "customers", disabled: true},
                                {label: "Scheduling", value: "scheduling"},
                                {label: "Installs", value: "installs"},
                            ]}
                        />
                    </Grid.Col>

                    <Grid.Col px={20} align="center"  bg="white" md={breakPointMd}  lg={breakPointLg}>
                        <TextInput value={generalSearchTerm} onChange={(e)=>{setGeneralSearchTerm(e.target.value)}}  placeholder="Search" />
                    </Grid.Col>

                </Grid>

            </Paper>

            <Modal fullScreen size={"xl"} opened={towerDetailModalOpened} onClose={()=>{handleCloseTowerDetail()}}>
                <TowerDetail tower_id={towerDetailID} />
            </Modal>
            {itemsToList === "installs" && <PreInstallList clickCreate={handleCreateMbr} items={preInstallItems} />}
            {itemsToList === "scheduling" && <ScheduleList items={scheduleItems}/>}
            {itemsToList === "towers" && <TowerList items={towerItems} leftClickView={leftClickView}/>}

        </>
    )
}