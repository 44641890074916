import { useEffect } from "react"
import { getPreInstalls } from "../hooks/emerald/useGetPreInstall"

export default function Emerald(){
    useEffect(()=>{
        getPreInstalls()
        .then((list)=>{
            console.log(list);
        })
    })

    return (
        <p>Text</p>
    )
}