import React from 'react'
import { NavLink, Input, Space, TextInput } from '@mantine/core'
import {  IconCalendar, IconChevronRight, IconPhone, IconHome, IconList, IconMap, IconSearch, IconDiamond } from '@tabler/icons-react';


function  NavbarContent({pageInView, setPageInView, searchText, setSearchText}) {
    return (
        <>
            <TextInput placeholder='Search for anything' value={searchText} onChange={(e) => setSearchText(e.target.value)} icon={<IconSearch  />} />
            <Space h="xl" />
            <NavLink active={pageInView=='sales'} onClick={() => setPageInView('sales')} icon={<IconPhone />} rightSection={<IconChevronRight />} label="Sales"/>
            <NavLink active={pageInView=='map'} onClick={() => setPageInView('map')} icon={<IconMap />} rightSection={<IconChevronRight />} label="Map"/>
            <NavLink active={pageInView=='list'} onClick={() => setPageInView('list')} icon={<IconList />} rightSection={<IconChevronRight />} label="List"/>
            <NavLink active={pageInView=='schedule'} onClick={() => setPageInView('schedule')} icon={<IconCalendar />} rightSection={<IconChevronRight />} label="Technician Schedule"/>
            {/* <NavLink active={pageInView=='emerald'} onClick={() => setPageInView('emerald')} icon={<IconDiamond />} rightSection={<IconChevronRight />} label="Emerald"/> */}

        </>
    )

}

export default NavbarContent