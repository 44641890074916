import MapWithSearchBox from "../components/maps/MapWIthSearchBox"
import { Space, Group, SimpleGrid, em, Alert, Flex } from "@mantine/core"
import React, { useEffect, useState } from 'react';


import CustomerInfo from "../components/sales/CustomerInfo";
import ServiceAndScheduling from "../components/sales/ServiceAndScheduling";

import { getTowers } from "../hooks/locations/useGetTowers"
import { signupCustomer } from "../hooks/customers/useSignupCustomer";
import { getCoveragePolygons } from "../hooks/coverage/useGetCoveragePolygons"

export default function Sales(){
    const [formErrors, setFormErrors] = useState({
        firstName: false,
        lastName: false,
        phoneNumber: false,
        emailAddress: false,
        addressOne: false,
        city: false,
        state: false,
        zip: false,
        mapMarker: false,
        speedPlan: false,
        router: false,
        installAppointment: false,
    })
    const [generalError, setGeneralError] = useState()

    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [emailAddress, setEmailAddress] = useState()
    const [addressOne, setAddressOne] = useState()
    const [addressTwo, setAddressTwo] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [zip, setZip] = useState()
    const [mapMarker, setMapMarker] = useState()
    const [speedPlan, setSpeedPlan] = useState()
    const [router, setRouter] = useState()
    const [installAppointment, setInstallAppointment] = useState()
    const [oneTimeDiscount, setOneTimeDiscount] = useState("")
    const [referralCode, setReferralCode] = useState("")
    const [specialNotes, setSpecialNotes] = useState("")


    //state related to the map objects
    const [towerLocations, setTowerLocations] = useState([])
    const [towerCoverages, setTowerCoverages] = useState([])
    const [reloadMapObjects, setReloadMapObjects] = useState(false)
    const [coveragePolygons, setCoveragePolygons] = useState([]);
    
    const [submitDisabled, setSubmitDisabled ] = useState(false);


    function fieldIsCompleted(field){
        if(field){
            return false
        }else{ 
            return true
        }
    }

    function handleFormErrors(){
        let temporaryErrorHolder = {}
        temporaryErrorHolder.firstName = fieldIsCompleted(firstName)
        temporaryErrorHolder.lastName = fieldIsCompleted(lastName)
        temporaryErrorHolder.phoneNumber = fieldIsCompleted(phoneNumber)
        temporaryErrorHolder.emailAddress = fieldIsCompleted(emailAddress)
        temporaryErrorHolder.addressOne = fieldIsCompleted(addressOne)
        temporaryErrorHolder.city = fieldIsCompleted(city)
        temporaryErrorHolder.state = fieldIsCompleted(state)
        temporaryErrorHolder.zip = fieldIsCompleted(zip)
        temporaryErrorHolder.mapMarker = fieldIsCompleted(mapMarker)
        temporaryErrorHolder.speedPlan = fieldIsCompleted(speedPlan)
        temporaryErrorHolder.router = fieldIsCompleted(router)
        temporaryErrorHolder.installAppointment = fieldIsCompleted(installAppointment)
        

        setFormErrors(temporaryErrorHolder)
        
        if(temporaryErrorHolder.firstName || 
            temporaryErrorHolder.lastName || 
            temporaryErrorHolder.phoneNumber ||
            temporaryErrorHolder.emailAddress || 
            temporaryErrorHolder.addressOne || 
            temporaryErrorHolder.city || 
            temporaryErrorHolder.state || 
            temporaryErrorHolder.zip || 
            temporaryErrorHolder.mapMarker ||
            temporaryErrorHolder.speedPlan ||
            temporaryErrorHolder.router ||
            temporaryErrorHolder.installAppointment){
                return true
            }
        else{
            return false
        }
    }
    
    function handleSubmit(){
        setSubmitDisabled(true);
        let allInfo = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            emailAddress: emailAddress,
            addressOne: addressOne,
            addressTwo: addressTwo,
            city: city,
            state: state,
            zip: zip,
            mapMarker: mapMarker,
            speedPlan: speedPlan,
            router: router,
            installAppointment: installAppointment,
            referralCode: referralCode,
            oneTimeDiscount: oneTimeDiscount,
            specialNotes: specialNotes

        }
        if(handleFormErrors()){
            setSubmitDisabled(false);
        }else{
            signupCustomer(allInfo)
            .then((response) => {
                console.log(response)
                if(response.status != "success"){
                    setGeneralError(response.message)
                    setSubmitDisabled(false);
                }else{
                    window.location.reload()
                }
                
            })
        }

    }

    useEffect(() => {
        handleFormErrors()
    }, [firstName, lastName, phoneNumber, emailAddress, addressOne, city, state, zip, mapMarker, speedPlan, router, installAppointment])

    useEffect(()=>{
        getTowers().then((res)=>{
            setTowerLocations(res.towers);
            setTowerCoverages(res.coverages);
        })
        getCoveragePolygons()
        .then((res)=>{
            setCoveragePolygons(res.polygons);
            console.log(res);
        });
        
    }, [reloadMapObjects])

    return(

        <div>
            
            <MapWithSearchBox 
                setMapMarker={setMapMarker}
                mapMarker={mapMarker}
                lat={33.09823522991901} lng={-111.70625043806123} 
                setAddressOne={setAddressOne} 
                setAddressTwo={setAddressTwo}  
                setCity={setCity}
                setState={setState}
                setZip={setZip}
                towerLocations={towerLocations}
                coverages={towerCoverages}
                enableEditPolygons={false}
                coveragePolygons={coveragePolygons}
                setCoveragePolygons={setCoveragePolygons}
            />
            <Space h="md" />
            {generalError && <Alert color="red">{generalError}</Alert>}
            <SimpleGrid
                cols={2}
                spacing="lg"
                breakpoints={[
                    { maxWidth: '75rem', cols: 1, spacing: 'sm' },
                ]}
                >
                <CustomerInfo 
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    emailAddress={emailAddress}
                    setEmailAddress={setEmailAddress}
                    addressOne={addressOne}
                    setAddressOne={setAddressOne}
                    addressTwo={addressTwo}
                    setAddressTwo={setAddressTwo}
                    city={city}
                    setCity={setCity}
                    state={state}
                    setState={setState}
                    zip={zip}
                    setZip={setZip}
                    mapMarker={mapMarker}
                    setMapMarker={setMapMarker}
                    formErrors={formErrors}
                    
                    />
                <ServiceAndScheduling 
                speedPlan={speedPlan} 
                setSpeedPlan={setSpeedPlan} 
                router={router} 
                setRouter={setRouter}
                installAppointment={installAppointment}
                setInstallAppointment={setInstallAppointment}
                handleSubmit={handleSubmit}
                formErrors={formErrors}
                oneTimeDiscount={oneTimeDiscount}
                setOneTimeDiscount={setOneTimeDiscount}
                specialNotes={specialNotes}
                setSpecialNotes={setSpecialNotes}
                submitDisabled={submitDisabled}
                setReferralCode={setReferralCode}
                referralCode={referralCode}
                />
            </SimpleGrid>
        </div>


    )
}